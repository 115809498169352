.header {
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;

  menu {
    display: flex;
    gap: 24px;

    li {
      list-style: none;
      color: var(--text-secondary, rgba(71, 81, 88, 0.50));
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 122.222% */

    }

    a.active{
      li{
        color: var(--text-accent-primary, #35A7EA) !important;

      }
    }

  }


}

.authLink {
  display: flex;
  gap: 12px;
  align-items: center;

  div, a{
    cursor: pointer;
  }

  &__profile{
    color: var(--text-accent-primary, #35A7EA);
    padding: 10px 12px;
    border-radius: 12px;
    background: var(--button-primary-default, rgba(53, 167, 234, 0.10));
  }

  &__logout{
    color: var(--text-accent-primary, #ea3535);
    padding: 10px 12px;
    border-radius: 12px;
    background: var(--button-primary-default, rgba(234, 53, 53, 0.1));

  }

  a {
    border-radius: 16px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 111.111% */
    cursor: pointer;
  }

  &__login {
    color: var(--text-accent-primary, #35A7EA);

    background: var(--button-primary-default, rgba(53, 167, 234, 0.10));
  }

  &__reg {
    color: var(--text-secondary, rgba(71, 81, 88, 0.50));


    background: var(--button-secondary-default, rgba(71, 81, 88, 0.05));
  }
}

.main {
  margin: 146px 0;
  text-align: center;

  &__title {
    margin-bottom: 24px;
    color: var(--text-primary, #475158);
    font-size: 96px;
    font-style: normal;
    font-weight: 600;
    line-height: 88px;
  }

  &__sub {
    color: var(--text-secondary, rgba(71, 81, 88, 0.50));
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
    width: 100%;
    max-width: 592px;
    margin: 0 auto;
  }
}

.elements {
  &__block {
    width: 100%;
    border-radius: 32px;
    background: var(--bg-island, #FCFDFE);
    overflow: hidden;
    /* block shadow */
    box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
    img{
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    margin-top: 24px;
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;


    &--left {
      display: flex;
      gap: 12px;
      color: var(--text-primary, #475158);

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 111.111% */
    }

    &-normal{
      color:  rgb(71, 81, 88);

    }

    &--opacity {
      color: var(--text-secondary, rgba(71, 81, 88, 0.50));
    }

    &--btn {
      color: var(--text-accent-primary, #35A7EA);

      font-size: 18px;
      font-style: normal;
      line-height: 20px; /* 111.111% */
      display: flex;
      align-items: center;
      padding: 10px 16px;
      border-radius: 16px;
      background: var(--button-primary-default, rgba(53, 167, 234, 0.10));

      img {
        margin-left: 4px;
      }
    }


  }
}


.solution {
  &__title {
    color: var(--text-primary, #475158);

    /* header/h2 */
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -1.92px;
    margin-top: 192px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 48px;
  }

  &__card--small {
    max-width: calc(100% / 2 - 12px);
    overflow: hidden;
  }

  &__card {
    width: 100%;
    height: 376px;
    padding: 48px 48px 0 48px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    border-radius: 40px;
    background: var(--bg-island, #FCFDFE);
    box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);


    &--text {
      display: flex;
      flex-direction: column;
      gap: 12px;
      text-align: center;

      h3 {
        color: var(--text-primary, #475158);
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 100% */
      }

      p {
        color: var(--text-secondary, rgba(71, 81, 88, 0.50));
        text-align: center;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 122.222% */


      }
    }

    .image {
      width: 500px;
      height: 200px;
      border-radius: 30px 30px 0 0;
      margin: 12px auto 0;
      background: #e5e5e5;
    }
  }


  &__container {
    display: flex;
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    flex-wrap: wrap;
    gap: 24px;
  }

  .p48 {
    padding: 0 48px 48px 48px;

    .image {
      border-radius: 0 0 30px 30px;
      margin-top: 0 !important;
    }

    * {
      text-align: right;
    }
  }

  .tal {
    * {
      text-align: left !important;

    }
  }

  .df {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 48px !important;

    .solution__card--text {
      max-width: 50%;

      * {
        text-align: left !important;

      }
    }

    .image {
      width: 40%;
      height: 300px;
      border-radius: 30px;
      overflow: hidden;
    }
  }

}

.image{
  overflow: hidden !important;
  background: #fff !important;
  height: fit-content !important;
  img{
    width: 100%;
  }
}

.edge {
  &__title {
    margin-top: 192px;
    color: var(--text-primary, #475158);
    text-align: center;
    font-size: 64px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -1.92px;
  }

  &__slider {
    display: flex;
    margin-top: 48px;
    gap: 24px;
    max-width: 100%;
    overflow-x: hidden;
    height: fit-content;
    scroll-behavior: smooth;
  }

  .edge__block {
    box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
    display: flex;
    flex-direction: column;
    gap: 48px;
    border-radius: 40px;
    background: var(--bg-island, #FCFDFE);
    min-width: 592px;
    height: 640px;
    padding: 48px;
    align-items: center;
    scroll-snap-align: center;

    &--image {
      width: 80%;
      border-radius: 30px;
      background: #e5e5e5;
      height: 100%;
      display: flex;
      justify-content: center;align-items: center;
      overflow: hidden;

      img{
        height: 100%;
      }
    }

    &--text {
      display: flex;
      flex-direction: column;
      gap: 12px;

      h3 {
        color: var(--text-primary, #475158);
        font-size: 32px;
        font-weight: 600;
        line-height: 32px;
      }

      p {
        color: var(--text-secondary, rgba(71, 81, 88, 0.50));
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

.slider__arrows {
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
  gap: 12px;

  img {
    cursor: pointer;
  }
}

.plans {
  margin-top: 192px;
  &.plansPage{
    margin-top: 48px;
  }
  &__title {
    color: var(--text-primary, #475158);
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px; /* 93.75% */
    letter-spacing: -1.92px;


  }

  &__header {
    display: flex;
    padding: 4px;
    justify-content: center;
    max-width: fit-content;
    align-items: center;
    gap: 4px;
    margin: 48px auto 0;
    border-radius: 20px;
    background: var(--bg-island, #FCFDFE);

    &--item {
      padding: 10px 16px;
      border-radius: 16px;
      cursor: pointer;
      color: var(--text-secondary, rgba(71, 81, 88, 0.50));

      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;

      &.active {
        background: var(--button-primary-default, rgba(53, 167, 234, 0.10));
      }
    }
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 24px;
  }

  &__item {
    width: calc(50% - 12px);

    &--block {
      display: flex;
      padding: 48px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      height: 100%;

      border-radius: 40px;
      background: var(--bg-island, #FCFDFE);
    }

    &--top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      gap: 24px;
      align-items: center;
    }

    &--title {
      color: var(--text-primary, #475158);

      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 100% */
    }

    &--price {
      color: var(--text-accent-primary, #35A7EA);

      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 100% */
    }

    &--text {
      color: var(--text-secondary, rgba(71, 81, 88, 0.50));

      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 122.222% */
    }
  }
}

.footer {
  margin-top: 192px;
  padding-bottom: 96px;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__social {
    display: flex;
    gap: 12px;
    justify-content: flex-end;

  }

  &__bottom {
    color: var(--text-secondary, rgba(71, 81, 88, 0.50));
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 111.111% */
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-top: 24px;
  }

  &__nav {
    display: flex;
    list-style: none;
    gap: 24px;
  }
}


@media screen and (max-width: 1200px) and (min-width: 800px) {

  .container {
    padding: 0 30px !important;
  }


}

@media screen and (max-width: 1200px) {
  .footer {
    * {
      flex-wrap: wrap !important;
    }
  }

  .footer__nav {
    flex-wrap: wrap;

    a{
      list-style: none;
      color: var(--text-secondary, rgba(71, 81, 88, 0.50));
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 122.222% */

    }

    a.active{
      li{
        list-style: none;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 122.222% */
        color: var(--text-accent-primary, #35A7EA);

      }
    }
  }


}

@media screen and (max-width: 799px) {
  .plans__item {
    width: 100% !important;
  }
  .container {
    padding: 0 20px !important;
  }

  .header {
    & > menu {
      display: none;
    }


  }

  .main__title {
    text-align: center;

    font-size: 48px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 91.667% */
    letter-spacing: -1.44px;

  }
}

.footer__copyright{
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 650px) {
  .solution__card {
    flex-wrap: wrap;
    height: fit-content;

    * {
      width: 100% !important;
    }
  }

  .solution__block {
    *, p {
      width: 100% !important;
      min-width: 100% !important;
    }
  }

  .solution__container {
    flex-wrap: wrap;

    * {
      width: 100% !important;
    }

    .solution__card {
      min-width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {

  .footer__top {
    gap: 24px;
  }

  .solution__card--text {

    h3 {
      font-size: 24px !important;
      text-align: center !important;
    }

    *, h3 {
      text-align: center !important;
    }


  }

  .solution__card, .solution__card.df {
    padding: 24px !important;
    gap: 12px;

  }

  .footer__links {
    flex-direction: row;
    gap: 12px;
  }

  .authLink__reg {
    display: none !important;
  }

  .main {
    margin: 63.5px 0;
  }

  .elements__block {
    //height: 364px;

  }




  .solution__title, .edge__title, .plans, .footer {
    margin-top: 96px;
  }

  .main__title, .solution__title, .edge__title, .plans__title, {
    font-size: 32px;
    line-height: 40px !important;
  }

  .plans__header {
    margin-top: 24px;
  }

  .plans__block {
    flex-wrap: wrap !important;


    button{
      padding: 10px 18px;
      border-radius: 24px;
      background: #35A7EA;
      color: white;
    }

  }


  .plans__item--block {
    padding: 24px;

    * {
      flex-wrap: wrap;
    }

    .plans__item--title {
      font-size: 32px;
    }
  }
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
  padding: 40px;
  border-radius: 40px;
  background: white;
  max-width: 600px;
  margin: 92px auto 0;


  &__title {
    color: #6B6B6B;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 43.2px */
    letter-spacing: -0.3px;
  }

  &__description {
    color: #6B6B6B;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.3px;
    max-width: 434px;
    margin: 10px 0 34px;
  }

  &__btn {
    display: flex;

    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: rgba(71, 81, 88, 0.05);
    color: var(--text-secondary, rgba(71, 81, 88, 0.60));

    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    cursor: pointer;
    line-height: 20px; /* 111.111% */

  }
}


.auth {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  margin-top: 92px;

  &__header {
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 4px;
    border-radius: 20px;
    background: var(--bg-island, #FCFDFE);

    &--item {
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      gap: 4px;
      color: var(--text-secondary, rgba(71, 81, 88, 0.60));

      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 111.111% */
      cursor: pointer;
      transition: .2s;

      &.active {
        background: var(--button-primary-default, rgba(53, 167, 234, 0.10));
      }
    }
  }

  &__container {
    display: flex;
    max-width: 592px;
    padding: 48px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    border-radius: 40px;
    width: 100%;
    background: var(--bg-island, #FCFDFE);
  }

  &__title {
    color: var(--text-primary, #475158);
    text-align: center;

    font-size: 32px;
    font-style: normal;
    font-weight: 550;
    line-height: 32px; /* 100% */
  }

  &__form--name {
    display: flex;
    gap: 12px;
  }

  &__input {
    width: 100%;
    display: flex;
    padding: 10px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    background: rgba(71, 81, 88, 0.05);

    &, &::placeholder {
      color: var(--text-placeholder, rgba(71, 81, 88, 0.70));
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 111.111% */
    }

    & {
      color: var(--text-placeholder, rgba(71, 81, 88, 1));
    }
  }

  &__sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__btn {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: var(--button-primary-default, rgba(53, 167, 234, 0.10));
    color: var(--text-accent-primary, #35A7EA);

    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 111.111% */
  }

  &__reset {
    color: var(--text-accent-primary, #35A7EA);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
}

.login {
  display: flex;
  flex-direction: column;

  gap: 48px;
  width: 100%;
  transition: 5s;

  &__header {
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 4px;
    justify-content: center;

    &--item {
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;

      color: var(--text-secondary, rgba(71, 81, 88, 0.60));

      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 111.111% */
      transition: .5s;
      cursor: pointer;

      &.active {
        border-radius: 16px;
        background: var(--button-primary-default, rgba(53, 167, 234, 0.10));
        color: var(--text-accent-primary, #35A7EA);
        font-size: 18px;
        font-style: normal;
        font-weight: 550;
        line-height: 20px; /* 111.111% */
      }
    }
  }

  .auth__input--wrapper {
    position: relative;
  }


}

.placeholder {
  position: absolute;
  top: -12px;
  left: 10px;
  padding: 0 5px;
  font-size: 12px;
}

.profile {
  margin-top: 92px;

  select {
    background: none;
    color: var(--text-secondary, rgba(71, 81, 88, 0.60));
    font-size: 16px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 111.111% */
    cursor: pointer;
  }

  &__right {
    width: 100%;
    display: flex;
    flex-direction: column;
  }


  &__title {
    font-size: 48px;
  }

  &__subtitle {
    font-size: 32px;
  }

  &__header {
    margin-top: 24px;
    display: flex;
    gap: 24px;
    width: 100%;

    &--item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      background: #FCFDFE;
      box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
      font-size: 18px;
      text-align: center;
      font-style: normal;
      font-weight: 550;
      line-height: 20px; /* 111.111% */
      padding: var(--Radius-medium, 10px) 16px;


      &:nth-child(1) {
        background: var(--button-primary-default, rgba(53, 167, 234, 0.10));
        color: var(--text-accent-primary, #35A7EA);
      }

      &:nth-child(2) {
        background: var(--button-primary-default, rgba(71, 234, 53, 0.1));
        color: var(--text-accent-primary, #245e1b);
      }

      &:nth-child(3) {
        background: var(--button-primary-default, rgba(234, 192, 53, 0.1));
        color: var(--text-accent-primary, #5e491b);
      }
    }

  }

  &__top {
    display: flex;
    gap: 24px;
    margin-top: 48px;

  }


  &__personal, &__company {
    border-radius: 32px;
    background: #FCFDFE;
    box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
  }

  &__personal {
    display: flex;
    min-width: 284px;
    padding: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

  }

  &__company--emps{
    display: flex;
    justify-content: space-between;
    width: 50%;
    gap: 12px;
  }

  &__company, {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;


    border-radius: 40px;
    background: var(--bg-island, #FCFDFE);
    box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
    padding: 48px;
    align-items: center;
    align-self: stretch;


    &--info {
      display: flex;
      gap: 12px;
      flex-direction: column;
    }

    &--emp {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

  }

  &__name {
    color: var(--text-primary, #475158);

    font-size: 32px;
    font-style: normal;
    font-weight: 550;
    line-height: 32px; /* 100% */
    display: flex;
    align-items: center;
    gap: 12px;

    a {
      font-size: 14px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      height: 32px;
      border-radius: 16px;
      background: linear-gradient(90deg, rgba(53, 167, 234, 0.10) 0%, rgba(235, 0, 255, 0.10) 100%);
      cursor: pointer;
      align-items: center;
      padding: 0 10px;
      color: rgba(3, 11, 100, 0.55);
    }
  }


  &__number {
    color: rgba(71, 81, 88, 0.62);

    font-size: 18px;
    font-style: normal;
    font-weight: 550;

    span {
      color: var(--text-accent-primary, #35A7EA);
      font-size: 18px;
      font-style: normal;
      font-weight: 550;
      line-height: 20px;
    }
  }

  &__bottom {
    display: flex;
    gap: 24px;

    & > div {
      width: 100%;
    }
  }

  &__emploee {
    border-radius: 32px;
    background: #FCFDFE;
    box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
    padding: 38px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    &--top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--role {
      border-radius: 16px;
      background: var(--button-secondary-default, rgba(71, 81, 88, 0.05));
      display: flex;
      padding: 8px 10px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      color: var(--text-secondary, rgba(71, 81, 88, 0.60));
      font-size: 16px;
      font-style: normal;
      font-weight: 550;
      line-height: 20px; /* 111.111% */
      cursor: pointer;
    }

    &--btn {
      border-radius: 16px;
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      font-size: 18px;
      font-style: normal;
      font-weight: 550;
      line-height: 20px; /* 111.111% */
      background: var(--button-primary-default, rgba(53, 167, 234, 0.10));
      color: var(--text-accent-primary, #35A7EA);

      &.second {

      }
    }

    &--block {
      display: flex;
      flex-direction: column;
      //max-width: calc((100vw - 1200px) / 2);
    }

    &--item {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--button-primary-default, rgba(0, 0, 0, 0.06));
    }

    &--name {
      font-size: 22px;
      position: relative;

      input {
        border-radius: 16px;
        background: var(--button-secondary-disabled, rgba(71, 81, 88, 0.02));
        display: flex;
        padding: 10px 16px;
        justify-content: space-between;
        align-items: center;

        &, &::placeholder {
          color: var(--text-placeholder, rgba(71, 81, 88, 0.60));

          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 111.111% */
        }

        color: #000 !important;

      }
    }

  }

  &__filters {
    .profile__emploee--links {
      display: flex;
      gap: 24px;

      & > div {
        padding: 0;
        background: none;
      }

      & > div:nth-child(1) {
        color: var(--button-primary-default, rgb(101, 90, 90));
      }

      & > div:nth-child(2) {
        color: var(--button-primary-default, rgba(53, 167, 234,));
      }
    }
  }

  &__btns {
    display: flex;
    gap: 12px;

    & > button {
      padding: 10px 15px;
      border-radius: 12px;

      &.profile__btn--select {
        background: rgba(0, 128, 0, 0.17);
        color: green;
      }

      &.profile__btn--all {
        background: rgba(53, 167, 234, 0.17);
        color: rgba(53, 167, 234);
      }
    }
  }

  &__filters {
    &--katos {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      padding: 24px 0 0;
      overflow-x: auto;
      scrollbar-width: none; /* Для Firefox */
      -ms-overflow-style: none; /* Для IE и Edge */
    }

    &--katos::-webkit-scrollbar {
      display: none; /* Для Chrome, Safari и Opera */
    }


    &--kato {
      padding: 10px 13px;
      border-radius: 12px;
      background: var(--button-primary-default, rgba(71, 234, 53, 0.1));
      color: var(--text-accent-primary, #245e1b);
      box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc((100% / 3) - (8px));
      text-align: center;
    }
  }

  &__keywords {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin: 12px 0;

    &--item {
      padding: 12px 10px;
      background: var(--button-primary-default, rgba(71, 234, 53, 0.1));
      color: var(--text-accent-primary, #245e1b);
      border-radius: 12px;
    }
  }
}

.mt24 {
  margin-top: 24px;
}

.w100 {
  width: 100% !important;

  input {
    width: 100%;
  }
}

.dacc {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;

  & > div {
  }
}

.df {
  display: flex;
  gap: 12px;

  & > div {
    position: relative;
    width: calc(50% - 6px);

    input {
      width: 100% !important;
    }
  }

  & > input {
    width: 50%;
  }
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 100%;
  max-width: 700px;
  margin: 0 auto !important;
  border: none !important;
  border-radius: 30px !important;
  box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
  padding: 30px 50px !important;
}


.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: rgba(0, 0, 0, 0.75) !important;

  button {
    padding: 10px 15px;
    border-radius: 12px;

    &.modal__close {
      background: rgba(255, 0, 0, 0.17);
      color: red;
      cursor: pointer;

    }

    &.modal__back {
      width: 100%;
      margin: 24px 0;
      cursor: pointer;

    }
  }
}

.filter {
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 48px;

  h2 {
    color: #475158;

    /* header/h3 */
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 91.667% */
    letter-spacing: -1.44px;
  }

  &__block {
    border-radius: 40px;
    background: var(--bg-island, #FCFDFE);
    box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
    display: flex;
    padding: 48px;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &.btn {
      justify-content: flex-end;
    }

    label {
      color: var(--text-secondary, rgba(71, 81, 88, 0.60));
      font-size: 18px;
      font-style: normal;
      font-weight: 550;
      line-height: 20px; /* 111.111% */
    }

    .custom-select .ant-select-selector {
      border-radius: 16px !important;
      background: var(--button-secondary-disabled, rgba(71, 81, 88, 0.03)) !important;
      color: var(--text-tertiary, rgba(71, 81, 88, 0.30)) !important;
      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 20px !important; /* 111.111% */
      padding: 10px 16px !important;
      border: none;
    }

  }

  &__btn {
    border-radius: 16px;
    background: var(--button-primary-default, rgba(53, 167, 234, 0.10));
    padding: 10px 16px;
    color: var(--text-accent-primary, #35A7EA);

    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 111.111% */
    display: flex;
    gap: 4px;
  }
}

.TendersGrid {
  margin-top: 96px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  h1 {
    color: var(--text-primary, #475158);

    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 91.667% */
    letter-spacing: -1.44px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sort__select .ant-select-selector {
      border-radius: 16px !important;
      background: var(--button-primary-default, rgba(53, 167, 234, 0.10));
      border: none;

      color: var(--text-accent-primary, #35A7EA);
      padding: 20px 16px !important;
      font-size: 18px;
      font-style: normal;
      font-weight: 550;
      line-height: 20px; /* 111.111% */
    }

  }
}

.sidebar {
  display: flex;
  width: 284px;
  height: fit-content;
  padding: 48px;
  flex-direction: column;
  gap: 24px;
  border-radius: 40px;
  background: var(--bg-island, #FCFDFE);
  box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);

  &__sub {
    color: var(--text-secondary, rgba(71, 81, 88, 0.60));
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 111.111% */
  }


  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__reset {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: var(--button-secondary-default, rgba(71, 81, 88, 0.05));
  }

  &__title {
    color: var(--text-primary, #475158);
    font-size: 24px;
    font-style: normal;
    font-weight: 550;
    line-height: 24px; /* 100% */
  }

  &__filter {
    flex-direction: column;
    width: fit-content;
    gap: 24px;
    padding: 0 !important;
    background: none;
    box-shadow: none;
    border-radius: 0;
  }

  .filter__item {
    gap: 12px;
  }

  &__item {
    display: flex;
    flex-direction: column;

    .sidebar__sub {
      margin-bottom: 12px;
    }

    .checkbox-container {
      display: flex;
      align-items: center;
      margin-top: 12px;

      input {
        display: none;
      }

      label {
        padding-left: 60px;
        font-size: 18px;
        position: relative;
        display: flex;
        align-items: center;
        word-break: break-all;

        & > span {
          display: none;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 48px;
          height: 40px;
          padding: 12px 16px;
          align-items: center;
          border-radius: 16px;
          background: var(--button-secondary-default, rgba(71, 81, 88, 0.05));
        }
      }

      input:checked + label::before {
        content: url("../img/checkbox.svg");
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }


  }


}


.dcc {
  display: flex;
  gap: 24px;

  & > div:nth-child(2) {
    width: 100%;
  }
}

.tendersList {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tender {
  display: flex;
  padding: 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 40px;
  background: var(--bg-island, #FCFDFE);
  box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);

  &__name {
    color: var(--text-primary, #475158);
    font-size: 24px;
    font-style: normal;
    font-weight: 550;
    line-height: 24px; /* 100% */
  }

  &__info {
    display: flex;
    gap: 24px;

    &--title {
      color: var(--text-secondary, rgba(71, 81, 88, 0.60));
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 111.111% */
    }

    &--text {
      margin-top: 6px;
      color: var(--text-primary, #475158);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 111.111% */
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &--left {
      gap: 6px;
      color: var(--text-primary, #475158);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 111.111% */
    }


    &--left, &--right {
      display: flex;
      align-items: center;
    }

    &--right {
      gap: 12px;
    }
  }


  &__btn {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: var(--button-secondary-default, rgba(71, 81, 88, 0.05));
    color: var(--text-secondary, rgba(71, 81, 88, 0.60));
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 111.111% */
    transition: 1s;
    cursor: pointer;

    &.favorite {
      background-color: rgba(53, 167, 234, 0.13);
      color: #35A7EA;

      img {
        display: none;
      }

    }
  }
}


.file {
  display: flex;
  padding: 24px;
  align-items: center;

  gap: 12px;
  border-radius: 16px;
  background: var(--bg-island, #FCFDFE);
  box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
  color: var(--text-primary, #475158);
  font-size: 18px;
  font-style: normal;
  font-weight: 550;
  line-height: 20px;

  &__name {
    color: var(--text-primary, #475158);
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 111.111% */
  }
}

.tenderPage {
  margin-top: 96px;

  &__top {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 48px;
  }

  &__desc {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &--title {
      color: var(--text-primary, #475158);
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px; /* 111.111% */
    }

    &--text {
      color: var(--text-primary, #475158);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 111.111% */
    }
  }

  &__titlePage {
    color: var(--text-primary, #475158);
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 91.667% */
    letter-spacing: -1.44px;
  }

  &__back {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    border-radius: 16px;
    background: var(--button-secondary-default, rgba(71, 81, 88, 0.05));
  }

  &__block {
    display: flex;
    padding: 48px;
    flex-direction: column;
    align-items: flex-start;

    gap: 24px;
    border-radius: 40px;
    background: var(--bg-island, #FCFDFE);
    box-shadow: 0 368px 103px 0 rgba(71, 81, 88, 0.00), 0 235px 94px 0 rgba(71, 81, 88, 0.00), 0 132px 79px 0 rgba(71, 81, 88, 0.01), 0 59px 59px 0 rgba(71, 81, 88, 0.02), 0 15px 32px 0 rgba(71, 81, 88, 0.02);
  }

  &__name {
    color: var(--text-primary, #475158);
    font-size: 24px;
    font-style: normal;
    font-weight: 550;
    line-height: 24px; /* 100% */
  }

  &__info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 24px;

    &--left {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    color: var(--text-secondary, rgba(71, 81, 88, 0.60));
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 111.111% */
  }

  &__content {
    color: var(--text-primary, #475158);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-align: right;
    line-height: 20px; /* 111.111% */

    &.needPlan {
      color: var(--text-tertiary, rgba(71, 81, 88, 0.30));
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 111.111% */
    }
  }

  &__dock {
    max-width: 400px;
    padding: 24px;
    flex-direction: column;
    display: flex;
    gap: 24px;
    border-radius: 40px;
    background: var(--bg-globe, #F7F8F9);

    &--title {
      color: var(--text-secondary, rgba(71, 81, 88, 0.60));
      text-align: center;

      font-size: 18px;
      font-style: normal;
      font-weight: 550;
      line-height: 20px; /* 111.111% */
    }

    &--block {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
    }
  }

  &__btns {
    display: flex;
    gap: 12px;
  }



  &__btn {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: linear-gradient(90deg, rgba(53, 167, 234, 0.05) 0%, rgba(218, 91, 250, 0.05) 100%);
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 111.111% */
    text-align: center;

    &:nth-child(1) {
      .tenderPage__btn--text {
        animation: gradientShift 3s ease-in-out infinite;

      }
    }

    &:nth-child(2) {
      .tenderPage__btn--text {
        animation: gradientShift 2.5s ease-in-out infinite;

      }
    }

    &:nth-child(3) {
      .tenderPage__btn--text {
        animation: gradientShift 1s ease-in-out infinite;

      }
    }

    &--text {
      background: linear-gradient(90deg, #59a2bd, #DA5BFA, #f542bc, #59a2bd);
      background-size: 400% 100%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;


    }
  }

  &__btnr{
    width: 100% !important;
    background: linear-gradient(90deg, rgba(53, 167, 234, 0.15) 0%, rgba(218, 91, 250, 0.13) 100%) !important;
    color: white !important;
  }

  &__btna{
    width: 100% !important;
  }


}


.profile__emploee--item{
  min-height: fit-content;
  padding: 12px 0;
}


@media screen  and (max-width: 568px){
  .edge {
    &__block{
      min-width: 80vw !important;
      width: 100% !important;
      padding: 36px !important;
      height: fit-content !important;

      &--image{
        width: 100% !important;
        height: 200px !important;
      }
    }
  }
}

.ReactModal__Content.ReactModal__Content--after-open {
  padding: 36px !important;
  z-index: 999999999 !important;
  h2{
    font-size: 24px;
    margin-bottom: 24px;

  }

  button{
    margin-right: 12px;
  }

  .filter-list{
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 18px;
    margin-bottom: 24px;


    label{
      padding: 12px 18px;
      background: rgba(54, 168, 235, 0.12);
      border: 1px solid #35A7EA;
      border-radius: 12px;
      color: #35A7EA;
      cursor: pointer;
      transition: 1s;

      &:hover{
        background: rgb(54, 168, 235);
        color: white

      }
      input{
        margin-right: 12px;
      }
    }
  }
}

@media screen and (max-width: 776px) {
  .ReactModal__Content.ReactModal__Content--after-open{
    max-width: 80vw !important;

    h2{
      //font-size: 48px;
    }
  }

}

.profile__header--item, .profile__emploee--btn {
  cursor: pointer;
}

.plans__block button {
  padding: 10px 18px;
  border-radius: 24px;
  background: #35A7EA;
  color: white;
}
/* PriceList.css */
.row-blue {
  background-color: #e6f7ff; /* Нежно голубой */
}

.row-white {
  background-color: #ffffff; /* Белый */
}

.profile__emploee--btn {
  background-color: #1890ff;
  color: white;
  border: none;
}

@media (max-width: 768px) {
  .profile__subtitle {
    font-size: 16px;
  }

  .profile__emploee--btn {
    font-size: 14px;
    padding: 8px;
  }

  .profile__emploee--top {
    flex-direction: column;
    align-items: flex-start;
  }
}
.color-scale-container {
  width: 100%;
  margin-bottom: 20px; // Отступ перед списком тендеров

  .color-scale__label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .color-scale {
    width: 100%;

    .color-scale__bar {
      height: 20px; // Высота полосы
      border-radius: 12px;
      background: linear-gradient(
                      to right,
                      rgba(0, 0, 255, 0.05), // Синий для 0%
                      rgba(0, 128, 255, 0.05), // Переход между синим и голубым
                      rgba(0, 255, 128, 0.05), // Переход между голубым и зеленым
                      rgba(0, 255, 0, 0.05), // Зеленый для 100%
                      rgba(0, 128, 0, 0.05) // Темно-зеленый
      );
    }
  }

  .color-scale__legend {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    .low-margin, .high-margin {
      font-size: 12px;
      color: #666;
    }
  }
}


.footer__nav a, .footer__nav a.active li{
  color: inherit !important;
}
