* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style-type: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: 400;
}

html, body{
  background: #F7F8F9;

}
*{
  font-family: "Manrope", sans-serif;
}

.container{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.active{
  color: #35A7EA !important;
}


 .ant-select-dropdown.css-dev-only-do-not-override-d2lrxs.ant-select-dropdown-placement-bottomLeft {
   border-radius: 22px;
   background:  #FCFDFE;
   margin-top: 20px !important;
   width: fit-content !important;
   padding: 6px;

   /* block shadow */
   box-shadow: 0px 368px 103px 0px rgba(71, 81, 88, 0.00), 0px 235px 94px 0px rgba(71, 81, 88, 0.00), 0px 132px 79px 0px rgba(71, 81, 88, 0.01), 0px 59px 59px 0px rgba(71, 81, 88, 0.02), 0px 15px 32px 0px rgba(71, 81, 88, 0.02);
}

.rc-virtual-list-holder-inner {
  display: flex;
  gap: 6px;
}

.ant-select-item-option-content{
  color: rgba(71, 81, 88, 0.60);

  font-size: 18px;
  font-style: normal;
  font-weight: 550;
  line-height: 20px;
}

.ant-select-item-option-content{
  /*padding: 10px 16px;*/
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active{
  border-radius: 16px !important;
}



.ant-select-item-option[title="По маржинальности"] > div {
  background: linear-gradient(90deg, #59a2bd, #DA5BFA, #f542bc, #59a2bd);
  background-size: 400% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;

  animation: gradientShift 3s ease-in-out infinite;
}

@keyframes gradientShift {
  0%, 100% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
}

