@media screen and (max-width: 1200px) {

  .elements__text, .elements__text--left{
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    text-align: center;
  }


  .tenderPage {
    margin-top: 0;

    &__titlePage {
      font-size: 24px;
    }

    &__top {
      margin-bottom: 12px;
    }

    &__block {
      padding: 36px;
      gap: 12px;
    }

    &__info {
      flex-wrap: wrap;
    }

    &__item {
      column-gap: 24px;
    }


    @media screen and (max-width: 565px) {
      &__item {
        flex-direction: column;

        &, & > * {
          text-align: left;
        }
      }

      &__title {
        font-size: 14px;
      }

      &__info {
        &--left {
          gap: 12px;
        }
      }
    }

    &__dock {
      max-width: 100%;
      width: 100%;
      border-radius: 24px;
      padding: 24px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
      }
    }

    @media screen and (max-width: 880px) {
      &__btns {
        flex-direction: column;
        width: 100%;

        div {
          width: 100%;
        }
      }
    }


  }

  .tender {
    &__bottom {
      flex-wrap: wrap;

      &--right {
        margin-top: 12px;
        flex-wrap: wrap;

        div {
          font-size: 14px;
          padding: 6px 19px;
          @media screen and (max-width: 584px) {
            width: 100%;


          }
        }
      }
    }
  }


  @media screen and (max-width: 980px) {
    .filter {

      h2 {
        font-size: 36px;
      }

      gap: 12px;

      &__block {
        flex-wrap: wrap;
        align-items: center;
        padding: 36px;
        gap: 12px;

        & > div, & > div > * {
          width: 100%;
        }
      }

      &__item {
        div, label {
          min-width: 100%;
        }
      }

      &__btn {
        padding: 6px 10px;
        display: flex;
        align-items: center;
        font-size: 18px;
        justify-content: center;

        img {
          height: 80%;
        }
      }
    }

  }

  .tender {
    padding: 36px;
  }
  .tender__info {
    flex-wrap: wrap;
  }
  .TendersGrid {
    &__top {
      flex-direction: column;
      text-align: center;
      gap: 12px;
    }

    h1 {
      font-size: 36px;
    }
  }

  .dcc {
    flex-direction: column;

    .sidebar {
      width: 100%;
    }
  }

  .profile__bottom {
    flex-direction: column;

    & > div {
      min-width: 100%;
    }
  }


  .profile__subtitle {
    font-size: 24px;
  }

  .profile__emploee--top {
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  .profile__emploee--item {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }

  .profile__emploee--links {
    justify-content: space-between;
    width: 100%;
  }

  .profile {
    margin-top: 24px;

    &__title {
      font-size: 36px;
    }

    &__company--info {
      width: 100%;
    }

    &__number {
      text-align: center;
    }

    &__company {
      flex-direction: column;
    }

    &__company--emps {
      width: 100%;
      text-align: center;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;

      & .profile__number {
        font-size: 14px;
      }
    }

    &__company--emp {
      & > span {
        font-size: 24px;
        gap: 0px;
      }
    }

    &__name {
      flex-direction: column;
      align-items: center;

    }
  }
  @media screen and (max-width: 540px) {

    .profile__header {
      flex-wrap: wrap;
      gap: 12px;
      justify-content: space-between;


      &--item {
        height: 50px;
        width: 100%;
      }

    }
  }

  .profile__top {
    display: flex;
    flex-direction: column;
  }

  .profile__emploee--item {
    height: fit-content;
    min-height: 72px;
  }

  @media screen and (max-width: 950px) {
    .profile__emploee--role {
      font-size: 14px;
      max-width: 200px !important;
    }
    .profile__emploee--name {
    }

    .dnm {
      display: none;
    }


  }


}

.hamburger-react {
  z-index: 999;
}

header {
}

.mobileMenu {
  z-index: 2;
  width: 100vw;
  height: fit-content;
  position: absolute;
  margin-top: 70px;
  top: 10px;
  left: 0;
  background: #35A7EA;

  &__header, &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    gap: 12px;

    div {
      background: white;
      height: 50px;
      width: 100%;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: inherit;
    }
  }

  &__footer {
    div {
      background: red;
      color: white;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 13px;
      font-size: 16px;
    }
  }
}


.hamburger-react {
  display: none;
}

@media screen and (max-width: 1200px) {
  .hamburger-react {
    display: flex;
  }


}
