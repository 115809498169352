.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: var(--bg-overlay, rgba(71, 81, 88, 0.20));
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__block {
    width: 100%;
    max-width: 592px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 40px;
    background: var(--bg-globe, #e0edfa);
    box-shadow: 0px 368px 103px 0px rgba(71, 81, 88, 0.00), 0px 235px 94px 0px rgba(71, 81, 88, 0.00), 0px 132px 79px 0px rgba(71, 81, 88, 0.01), 0px 59px 59px 0px rgba(71, 81, 88, 0.02), 0px 15px 32px 0px rgba(71, 81, 88, 0.02);

  }

  &__title {
    color: var(--text-primary, #475158);
    font-size: 32px;
    font-style: normal;
    font-weight: 550;
    line-height: 32px; /* 100% */
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  &__code {
    font-size: 32px;
    opacity: .3;
    letter-spacing: 10px;
  }

  &__bal {
    width: 100%;

    &__block {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;
    }

    input {
      padding: 12px 20px;
      width: 100%;
      background: white;
      opacity: 1;
      border-radius: 12px;
      font-size: 18px;
    }
  }


  &__btns {
    display: flex;
    gap: 12px;
  }


  &__close {
    cursor: pointer;
    background: white;
    border-radius: 16px;
  }

  &__btn, &__btn_second, &__btn_green {
    display: flex;
    cursor: pointer;
    padding: var(--Radius-medium, 10px) 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: var(--button-primary-default, rgba(53, 167, 234, 0.10));
    color: var(--text-accent-primary, #35A7EA);
    font-size: 18px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 111.111% */
  }

  &__error {
    color: red;
  }


  &__btn_second {
    background: var(--button-secondary-default, rgba(71, 81, 88, 0.05));
    color: var(--text-secondary, rgba(71, 81, 88, 0.60));
  }

  &__btn_green {
    background: var(--button-primary-default, rgba(53, 234, 56, 0.1));
    color: var(--text-accent-primary, #148315);
  }

}
